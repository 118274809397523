import Vue from "vue";
import VueApollo from 'vue-apollo'
import App from "./App.vue";
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: 'http://localhost:6112/graphql',
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})


Vue.use(VueApollo);
Vue.config.productionTip = false;

const apolloProvider = new VueApollo({defaultClient: apolloClient})


new Vue({
  apolloProvider,
  render: h => h(App)
}).$mount("#app");
