<template>
  <div>
    <li v-bind:key="index" v-for="(house, index) in housingdata">
      {{ house.municipalityCode }}, {{ house.floorsCount }},
      {{ house.coordinate }}
    </li>
  </div>
</template>

<script>
import gql from "graphql-tag";

let myCode = 849;

export default {
  name: "apollofaetter",

  apollo: {
    housingdata() {
      
      return {
        query: gql`
      query housingdata($municipalityCode: Int!) {
        housingdata: buildings(
          municipalityCode: $municipalityCode
          buildingApplication: 120
          area: { lower: 0, upper: 100000 }
          constructionYear: { lower: 1500, upper: 2020 }
        ) {
          municipalityCode
          constructionYear
          buildingAreaTotal
          floorsCount
          coordinateRaw
          coordinate {
            latitude
            longitude
          }
          exteriorWallMaterial
        }
      }
    `,
    variables() {
      return {
        municipalityCode: myCode
      };
    }
      }
    }
  },
};
</script>